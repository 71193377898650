import React from 'react';
import { Equation } from 'react-equation';
import { Divider } from 'semantic-ui-react';
import { EquationContainer, FlexContainer } from '../shared/FlexContainer';
import Label from '../shared/Label';
import List from '../shared/List';

const StakeInfo = () => {
  return (
    <FlexContainer className="column" gap={16}>
      <Label>The eckoDEX Staking tool accomplishes three main purposes:</Label>

      <List
        paddingLeft={16}
        items={[
          `Passive Income Source: stakers receive 0.05% from all swapping fees. Rewards re-staking (“Compounding”) is available once a week, starting at end of your waiting time.`,
          'Users’ Empowerment: staking allows users to increase their DAO voting Power in a way that values long-term holders.',
          "Deflation of KDX's overall supply: all penalties generated by early unstaking are constantly burnt.",
        ]}
      />
      <Divider />
      <Label>
        Adding more KDX to your stake, will simply increase your waiting time proportionally, and it will also activate a 3% flat penalty fee on any
        unstaked amount for 72hours. Once the 72 hours have passed, you can unstake your tokens normally without paying this penalty.
      </Label>
      <Label>Waiting Time Formula: </Label>
      <EquationContainer>
        <Equation className="equation" value="w = (60*n + r*p)/(n+p)" />
      </EquationContainer>
      <Label>where:</Label>
      <List
        listType="circle"
        items={[
          'w denotes waiting time adjustment when adding more stake;',
          'r is the previous waiting time (user position in the penalty curve);',
          'p is the previous staked amount',
          'n is the newly staked amount.',
        ]}
      />
    </FlexContainer>
  );
};

export default StakeInfo;
